import { useEffect, useState } from "react";
import Graph, { Options } from "react-graph-vis";
import GraphDisplayerProps from "../../interfaces/props/GraphDisplayerProps";
import { generateNewGraph, graphManager, getDefaultGraph } from "../../utils/graphManager";
import GraphInformation from "../graph/GraphInformations";
import Informations from "../../interfaces/graph/Informations";
import queryString from "query-string";

export default function GraphDisplayer(props: GraphDisplayerProps) {
    const [graph, setGraph] = useState(getDefaultGraph(props.startingAddress));

    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        generateNewGraph(props.startingAddress, parsed["secret"] as string);
        graphManager!.setUpdateFunction(setGraph);
    }, [props.startingAddress]);

    // The information holder.
    // If both are null: no edge are clicked.
    // If one of the two is clicked: then we display informations about the edge/node
    // If both are clicked: An error is thrown.
    const [informations, setInformations] = useState<Informations>({
        nodeInfo: null,
        edgeInfo: null
    });

    function setNodeInfo(index: number) {
        const infos = graphManager?.getNodeInfos(index);
        if (infos) {
            setInformations({
                nodeInfo: infos,
                edgeInfo: null
            });
        }
    }

    function setEdgeInfo(index: number) {
        const infos = graphManager?.getEdgeInfos(index);
        if (infos) {
            setInformations({
                nodeInfo: null,
                edgeInfo: infos
            });
        }
    }

    // set options: https://visjs.github.io/vis-network/docs/network/
    // TODO: Use physics but don't move everything everywhere
    // TODO: The graph does not seems very nice... like it refresh it every time
    //       which moves everything everywhere.
    const options: Options = {
        physics: {
            enabled: false
        }
    };

    const events = {
        select: (event: any) => {
            const { nodes, edges } = event;
            if (nodes.length === 1) {
                setNodeInfo(nodes[0]);
            } else if (edges.length === 1) {
                setEdgeInfo(edges[0]);
            } else if (edges.length > 0) {
                // Got never fired
                // console.log("MULTIPLE EDGES SELECTED!");
            } else if (nodes.length > 0) {
                // Got never fired
                // console.log("MULTPLE NODES SELECTED!");
            } else {
                // This one is called when we deselect everything
                setInformations({
                    nodeInfo: null,
                    edgeInfo: null
                });
            }
        },
    };

    return (
        <div className="h-screen">
            <GraphInformation infos={informations} />
            <Graph
                graph={graph}
                options={options}
                events={events}
            // It has to be recreated each time: https://github.com/crubier/react-graph-vis/issues/92
            //key={uuidv4()}
            />
        </div>
    )
}
