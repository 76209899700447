import GraphInformationProps from "../../interfaces/props/GraphInformationProps";
import EdgeInformation from "./EdgeInformations";
import NodeInformation from "./NodeInformation";

export default function GraphInformation(props: GraphInformationProps) {
    if (props.infos.nodeInfo && props.infos.edgeInfo) {
        throw new Error("Edge info and node info can't be filled at the same time");
    } else if (props.infos.nodeInfo) {
        return <NodeInformation nodeInfo={props.infos.nodeInfo} />;
    } else if (props.infos.edgeInfo) {
        return <EdgeInformation edgeInfo={props.infos.edgeInfo} />;
    } else {
        // It comes there when we have nothing to show
        return null;
    }
}
