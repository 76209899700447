import queryString from 'query-string';
import { useState } from 'react';
import GraphDisplayer from './components/pages/GraphDisplayer';
import SearchAddress from './components/pages/SearchAddress';

function App() {
  const [startingAddress, setStartingAddress] = useState("");


  const parsed = queryString.parse(window.location.search);

  if (!parsed.secret) {
    return <div>Please specify your alchemy key. contact@clarensia.com for more info</div>;
  }

  if (startingAddress === "") {
    return <SearchAddress onSubmit={setStartingAddress} />;
  } else {
    return <GraphDisplayer startingAddress={startingAddress} />;
  }
}

export default App;
