import NodeInformationProps from "../../interfaces/props/NodeInformationProps";
import { graphManager } from "../../utils/graphManager";

export default function NodeInformation(props: NodeInformationProps) {
    const nodeInfo = props.nodeInfo;

    /**
     * Expand the current node
     */
    function expandNode() {
        if (nodeInfo.isExpanded) {
            // Should never happen
            throw Error("The node that you are trying to expand has already been expanded");
        } else {
            graphManager?.expandNode(nodeInfo.id);
        }
    }

    // TODO: maybe add just a label above the node with the complete address when the user click
    // on a node that would allow him to expand the node
    // TODO: When a node is not expanded, then the button is disabled with a "?" when you hove on it and a message is displayed saying: "This node has already been expanded".
    return <div className="absolute z-10 w-4/12 h-screen bg-zinc-700 opacity-75">
        <div className=" bg-black text-white text-center text-lg p-1.5 rounded m-1 mx-2">
            {nodeInfo.address}
        </div>
        {!nodeInfo.isExpanded && <button type="button" onClick={expandNode} className="absolute bottom-0 right-0 text-lg focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg px-5 py-2.5 mr-2 mb-2">
            Expand Node
        </button>}
    </div>;
}
