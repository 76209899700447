import { Alchemy, AssetTransfersCategory, AssetTransfersWithMetadataResult, Network, SortingOrder } from "alchemy-sdk";
import { graphData } from "react-graph-vis";
import { Node, Edge } from "vis";
import NodeInfo from "../interfaces/graph/NodeInfo";

/**
 * This function allow us to truncate the address given by the user, this way, it does not need to change.
 * @param {string} address The address that we have to format
 * @returns The address but with only the first 6 character and the last 4
 */
function formatAddress(address: string) {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
}

/**
 * Get the default graph from the given starting address
 * @param address The starting address
 */
export function getDefaultGraph(address: string): graphData {
    return {
        nodes: [ { id: 0, label:formatAddress(address)} ],
        edges: []
    };
}

/**
 * This class is responsible for:
 * - Updating the graph
 * - Giving informations about a specific node
 * - Giving informations about a specific edje
 * 
 * It keeps the long informations and keep them inside of the graph
 * 
 * Attributes:
 *  node_to_address: An array having at index the index of node inside of the graph and as value
 *                   the address that this node contains.
 *  nodes: The list of the nodes as they are inside of the Graph object from react-graph-vis
 *  edges: The list of edges as they are inside of the Graph object from react-graph-vis
 *  update_graph_function: The function to call to update the graph object each time there is a change
 *                         to nodes or edges. This method is called to refresh the graph.
 */
class GraphManager {
    private _node_to_address: string[];
    private _address_to_node: Map<string, number>;
    private _edge_to_transaction: AssetTransfersWithMetadataResult[];
    private _nodes: Node[];
    private _edges: Edge[];
    private _update_graph_function: null | ((data: graphData) => void);
    // Contains nodes that has been expanded
    private _expanded_nodes: Set<number>;
    private _alchemy: Alchemy;

    /**
     * Create a NodeManager
     * @param {string} initial_node The address of the starting node
     */
    constructor(initial_node: string, alchemyKey: string) {
        initial_node = initial_node.toLowerCase();
        // This array contains at index the id of the node and as value the address stored at this index
        this._node_to_address = [initial_node];
        this._address_to_node = new Map<string, number>();
        this._address_to_node.set(initial_node, 0);
        this._edge_to_transaction = [];

        this._nodes = [
            // TODO: Maybe add a title to the node with the full address
            { id: 0, label:formatAddress(initial_node) },
        ];

        this._edges = [

        ];

        this._update_graph_function = null;
        this._expanded_nodes = new Set();

        this._alchemy = new Alchemy({
            apiKey: alchemyKey,
            network: Network.ETH_MAINNET
        });
    }

    setUpdateFunction(update_graph_function: (data: graphData) => void) {
        this._update_graph_function = update_graph_function;
    }

    /**
     * Get informations about a specific node
     * @param {number} index The index of the node inside of the graph
     * @returns An object containing informations about the node.
     */
    getNodeInfos(index: number): NodeInfo {
        const address = this._node_to_address[index];
        return {
            id: index,
            address: address,
            // null address is allways expanded because we can't expand it
            isExpanded: address === "0x0000000000000000000000000000000000000000" || this._expanded_nodes.has(index)
        };
    }

    getEdgeInfos(index: number): AssetTransfersWithMetadataResult {
        return this._edge_to_transaction[index];
    }

    /**
     * This method generate a graph that can be directly passed to Graph component from
     * react-graph-vis
     * @returns An object that can be given for the graph to update (a copy of edges and nodes)
     */
    generateStateGraph(): graphData {
        // We must create copies because the state in React should be immutable
        return {
            nodes: JSON.parse(JSON.stringify(this._nodes)),
            edges: JSON.parse(JSON.stringify(this._edges))
        };
    }

    /**
     * Verify if the node exists, if it does not then we add it
     * @param address The address of the node to add
     */
    private _addNode(address: string | null) {
        if (address === null) {
            address = "0x0000000000000000000000000000000000000000";
        }
        address = address.toLowerCase();
        if (!this._address_to_node.has(address)) {
            const id = this._node_to_address.length;
            this._node_to_address.push(address);
            this._address_to_node.set(address, id);
            this._nodes.push({
                id: id,
                label: formatAddress(address)
            });
        }
    }

    /**
     * Create a new edge for the given transfer
     * @param transfer The transfer that was done for the creation of this edge
     */
    private _addEdge(transfer: AssetTransfersWithMetadataResult) {
        const id = this._edge_to_transaction.length;
        this._edge_to_transaction.push(transfer);
        let to = transfer.to;
        if (!to) to = "0x0000000000000000000000000000000000000000";
        this._edges.push({
            id: id,
            from: this._address_to_node.get(transfer.from),
            to: this._address_to_node.get(to)
        });
    }

    private _addTransfersToGraph(transfers: AssetTransfersWithMetadataResult[]) {
        transfers.forEach(transfer => {
            // adding nodes
            this._addNode(transfer.from);
            this._addNode(transfer.to);

            const from_index = this._address_to_node.get(transfer.from);
            let to = transfer.to;
            if (!to) {
                to = "0x0000000000000000000000000000000000000000";
            }
            const to_index = this._address_to_node.get(to);

            if (!this._expanded_nodes.has(from_index!) && !this._expanded_nodes.has(to_index!)) {
                this._addEdge(transfer);
            }
        });
    }

    /**
     * Expand the given node with calls to the Alchemy API
     * @param index The index of the node that we have to expand
     */
    async expandNode(index: number) {
        const address = this._node_to_address[index];
        const all_out_transfers = await this._alchemy.core.getAssetTransfers({
            fromBlock: "0x0",
            fromAddress: address,
            excludeZeroValue: true,
            category: [AssetTransfersCategory.ERC20, AssetTransfersCategory.ERC721, AssetTransfersCategory.ERC1155],
            order: SortingOrder.DESCENDING,
            withMetadata: true
        });

        // TODO: handle more pages with all_out_transfers.pageKey: https://docs.alchemy.com/reference/alchemy-getassettransfers
        let transfers = all_out_transfers.transfers;

        const all_in_transfers = await this._alchemy.core.getAssetTransfers({
            fromBlock: "0x0",
            toAddress: address,
            excludeZeroValue: true,
            category: [AssetTransfersCategory.ERC20, AssetTransfersCategory.ERC721, AssetTransfersCategory.ERC1155],
            order: SortingOrder.DESCENDING,
            withMetadata: true
        });

        // TODO: Handle more pages
        transfers = transfers.concat(all_in_transfers.transfers);

        this._addTransfersToGraph(transfers);

        this._expanded_nodes.add(index);
        this._update_graph_function!(this.generateStateGraph());
    }
}

// The node manager is set inside of the class because it should not be updated with the component
// but it is something external to it.
export let graphManager: null | GraphManager = null;

/**
 * Create the graph from a given starting point.
 */
export function generateNewGraph(starting_point: string, alchemyKey: string) {
    graphManager = new GraphManager(starting_point, alchemyKey);
}
