import React from "react";
import SearchAddressProps from "../../interfaces/props/SearchAddressProps";
import SearchAddressFormElement from "../../interfaces/forms/SearchAddressFormElement";


export default function SearchAddress(props: SearchAddressProps) {

    function handleSubmit(e: React.FormEvent<SearchAddressFormElement>) {
        e.preventDefault();
        props.onSubmit(e.currentTarget.elements.address.value);
    }

    return (
        <div className="grid h-screen place-items-center">
            <div className="w-full max-w-2xl bg-white border border-gray-200 rounded-lg shadow-md">
                <h2 className="text-2xl mt-5 text-center">Search for an ethereum address</h2>
                <form className="mt-7 mx-8" onSubmit={handleSubmit}>
                    <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        </div>
                        <input type="search" id="default-search" name="address" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0xa344c7aDA83113B3B56941F6e85bf2Eb425949f3" required />
                            <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                    </div>
                </form>
                <div className="text-center mt-6 mb-1 text-sm text-slate-500 italic">Blocktracking version 0.0.1 made by <a href="https://www.clarensia.com/" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">Clarensia</a></div>
            </div>
        </div>
    )
}
