import EdgeInformationProps from "../../interfaces/props/EdgeInformationProps";

export default function EdgeInformation(props: EdgeInformationProps) {
    const edgeInfo = props.edgeInfo;

    return <div className="absolute z-10 w-5/12 h-screen bg-zinc-700 opacity-75">
        <div className=" bg-black text-white text-center text-lg p-1.5 rounded m-1 mx-2">
            {edgeInfo.hash}
        </div>
        <ul className="text-center text-lg text-white self-auto">
            <li>From: {edgeInfo.from}</li>
            <li>To: {edgeInfo.to}</li>
            <li>{edgeInfo.value && "Value: " + edgeInfo.value}</li>
            <li>{edgeInfo.asset && "Asset name: " + edgeInfo.asset}</li>
            <li>Token contract: {edgeInfo.rawContract.address}</li>
            <li>Time of transfer: {edgeInfo.metadata.blockTimestamp}</li>
        </ul>
        <a href={"https://etherscan.io/tx/" + edgeInfo.hash} target="_blank" rel="noreferrer"
           className="text-4xl absolute bg-[#21325B] text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center bottom-4 left-1/2 transform -translate-x-1/2"
        >
            View in explorer
        </a>
    </div>;
}

/*
    design by zone
    Transfer
    FROM  ---> TO
    Amount: token amount
    time

    Token informations:
    Address: 0x....
    type: ERC20
    name: UNI

         view in exporer (link to explorer)
*/